.pageContainer {
  display: flex;
  .sidebar {
    min-width: 320px;
    max-width: 320px;
    box-shadow: 0 0 24px 2px #0003;

    .projectDropdown {
      padding: 0 0.4rem;
      border: none;
      &:focus-within {
        box-shadow: none;
        border-color: #007bff;
        //background-image: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23333' d='M0 0L2 2L4 0z'/></svg>"); /* Up arrow */
      }
      //appearance: none; /* Removes default arrow styling */
      //-webkit-appearance: none;
      //-moz-appearance: none;
      //background-image: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23333' d='M2 0L0 2h4z'/></svg>");
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 10px;
      option {
        background-color: white; /* Background for dropdown options */
        color: #333;
        padding: 10px; /* Not supported on all browsers */
        border-radius: 10px;
      }
    }
  }

  main {
    flex-grow: 1;
  }
}
.nav-tabs {
  border: none!important;
  .nav-link {
    font-weight: 500;
    color: #71797E;
    &:hover, &:active {
      border-color: white!important;
    }
    &.active {
      //background-color: white!important;
      color: #333 !important;
      border: none;
      border-bottom: 2px solid #333;
      border-bottom-color: #444 !important;
    }
  }
}
.chatbot {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  cursor: pointer;
}
.actionBar {
  background: linear-gradient(180deg, #F7F7F7, transparent)!important;
}

.initialize_chat_modal {
  height: 100%;
  .modal-content {
    height: 85%;
    width: 100%;
    overflow: hidden;
  }
}