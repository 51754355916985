.tabMenuItem {
  padding: 0.3rem 0;
  margin-right: 2.8rem;
  font-size: 14px;
  color: #333!important;
  &:last-child {
    margin-right: 0;
  }
  .icon {
    width: 28px;
    height: 28px;
  }
}