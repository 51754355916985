.managePage {

  main {
    flex-grow: 1;
    .sort {
      img {
        width: 17px;
        height: 17px !important;
      }
    }
  }
}