@import "../../../../../assets/scss/theme";
.card {
  border-radius: 2rem;
  border: none;
  height: 200px !important;
  margin: 0;
  padding: 0;
  background-color: $bg;
  background-image: url("../../../../../assets/images/project_placeholder.png") !important;
  background-size: 100% 200px !important;
  background-repeat: no-repeat;
  background-blend-mode: normal;


  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $bg;
    opacity: 0.5;
    z-index: 2;
    pointer-events: none;
  }

  label {
    background: $primary;
    width: auto;
    color: white;
    max-width: 180px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    border-radius: $border-radius;
    padding: 0.6rem 1.5rem;
    position: absolute;
    left: 0;
    margin: 0.8rem;
    z-index: 4;
  }
  .user-profile{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    height: 30px;
    justify-content: center;
    align-items: center;

  }
}
.default{
  border-top-color: #1b4c93;
  border: 4px solid $primary;
}