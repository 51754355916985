@import "../../assets/scss/variables";
.dd {
  .modal-dialog {
    min-height: 80%;
  }
  .modal-content {
    height: 80%;
    width: 100%;
    overflow: hidden;
    background-color: #0005!important;
    border: none;
    position: relative;
    padding-top: 0;

    .btn-close-dialog {
      background-color: #FFF3;
      margin-right: 0.5rem;
      width: 30px;
      height: 30px;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
  .drawingDialog {
    background-color: white !important;
    width: 100%;
    min-width: 100%;
    height: auto;
    border-radius: 0.8rem;


    .modalBody {
      width: 100%;
      display: flex;
      .mainPanel {
        background-color: white;
        min-width: 400px;
        flex-grow: 1;

        .drawingContainer {
          width: 100%;
          height: 100%;
          min-height: 726px;
          position: relative;
        }
      }

      .sidePanel {
        background-color: white;
        width: 40%;
      }
    }
  }
}
