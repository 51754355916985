//@import "../../../../../../assets/scss/theme";
$border-color: #AAA;
$border-radius: 1.5rem;
.documents {

  table {
    border-collapse: separate!important;
    border-spacing: 0 0.5em;
    tr {
      //background: #FFF;
      th {
        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          border-left: 1px solid $border-color;
          padding-left: 1.5rem;
          width: 34px;
        }
        &:last-child {
          border-top-right-radius:  $border-radius;
          border-bottom-right-radius:  $border-radius;
          border-right: 1px solid $border-color;
          padding-right: 1.5rem;
          width: 34px;
        }
      }
      td {
        background: #FFF;

        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        padding: 1em;
        &:first-child {
          background: transparent;
          border: none;
        }
        &:nth-child(2) {
          border-top-left-radius:  $border-radius;
          border-bottom-left-radius:  $border-radius;
          border-left: 1px solid #ffffff;
        }
        &:last-child {
          border-top-right-radius:  $border-radius;
          border-bottom-right-radius:  $border-radius;
          border-right: 1px solid #ffffff;
        }
      }
    }
    .archieve{
      tr{
        background-color: #71797E;
      }
    }
  }
  .uploadIcon{
   color:#ffffff;
  }

}

.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Faded background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it is above other elements */
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}