.confirmation_modal {
    height: auto !important;

    .modal-content {
        height: auto !important;
    }
}

#car1
{
    padding-top:15px;
}
p{
    font-size:15px;
}
#para{
    padding-top: 16px;
}
input{
    padding-top:4px;
}
.btn_delete,.btn_delete:hover{
    padding: 5px 10px;
    background: #ef4146 !important;
    color: #fff !important;
    border: #ef4146 !important;
    font-size: 15px;
    border-radius: 3px;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{
    background-color: #ef4146;
    opacity: 0.6;
    border-color: #ef4146;
}
.btn_cncl,.btn_cncl:hover{
    padding: 5px 10px;
    background-color: #ececf1 !important;
    color: #353740 !important;
    border-color: #ececf1 !important;;
    font-size: 16px;
    border-radius: 3px;
}