@import "../../assets/scss/theme";
.registerPanel {
  width: 600px;
  background-color: white;
  border-radius: $border-radius;
  padding: 0.7rem 1.5rem;
  border: 1px solid $border-color;

  .btn:disabled, .btn.disabled, fieldset:disabled .btn{
    background-color: #1b4c93;
    border-color: #1b4c93;
  }
}