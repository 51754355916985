$primaryLight: #329dea;
$primary: #1b4c93;
$secondary: #717171;
$light: #E9E9E9;
$dark: #000;
$info: #000;
$danger: #F00;
$success:  #14A44D;
$warning: #E4A11B;
$bg: #F8F8F8;
$body-bg: $bg;
$border-radius: 2rem;
$border-radius-sm: 2rem;
$border-radius-lg: 2rem;
$accent-color: #FBDE3F;
$border-color: #dee2e6;