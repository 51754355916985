@import "./variables";
$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        info: $info,
        danger: $danger,
        success: $success,
        warning: $warning,
        light: $light,
        dark: $dark
);

@import "bootstrap/scss/bootstrap";

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-light {
  background-color: $primaryLight;
}
.customScrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    height: 2px;
    padding: 0;
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .text-accent {
    color: $accent-color!important;
  }
}