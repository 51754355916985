@import "../../assets/scss/variables";
.appNotificationContainer {
  padding: 1.5rem;

  .avatar {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    background-color: #DB8C30;
  }

  .avatarApproved {
    background-color: $success!important;
  }
  .avatarRejected {
    background-color: $danger!important;
  }
}