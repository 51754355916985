@import "../../../../assets/scss/theme";

.projects {
  table {
    border-collapse: separate !important;
    border-spacing: 0 0.5em;

    tr {
      //background: #FFF;
      td, th {
        &:first-child {
          width: 35px;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          border-left: 1px solid $border-color;
          padding-left: 1.5rem;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          border-right: 1px solid $border-color;
          padding-right: 1.5rem;
          width: 35px;
        }
      }

      td {
        background: #FFF;

        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding: 0.5em;

      }
    }
  }

  .imgThumbnail {
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    height: 35px;
    width: 35px;
    border-radius: 3rem;
    object-fit: cover;
  }

  .dropdown {
    height: 40px;
    width: 30px;
  }

  .dropdown-toggle:after {
    display: none !important;
  }

  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:focus, &:hover, &:active, &:target {
      padding: 0;
      background: transparent;
      border: none;
    }
  }

  /*.dropdown-toggle:active {
    background: $light;
    border: none;
  }*/
  .dropdown-menu {
    background-color: white;
    box-shadow: 0 0 5px #0005;
    padding: 0.7rem 0.2rem;
    border: none;
    max-width: 80px; //This, by the way, is not working for some reason.
    border-radius: 0.4rem;
    margin: 0;
  }

  .imgThumbnail {
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    height: 35px;
    width: 35px;
    border-radius: 3rem;
    object-fit: cover;
  }
}

.projectTitle {
  width: 24px;
  max-width: 24px;
  height: 24px;
  max-height: 24px;
  margin: 0;
  overflow: hidden;
}

.status_badge {
  padding: 0.4rem 0.5rem !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
}

#status_progress {
  background-color: #007BFF;
}

#status_done {
  background-color: #28A745;
}