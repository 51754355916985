@import "../assets/scss/theme";
.toastContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  justify-content: end;
  padding-top: 50px;
  z-index: 108!important;

  .toastBar {
    min-width: 260px;
    width: 260px;
    height: 100%;

    .toast {
      display: flex;
      flex-direction: column;
      pointer-events: all;
      background-color: white;
      padding: 1rem;
      border-radius: $border-radius;

      .toastHeader {
        font-weight: bold;
        font-size: 16px;
      }
      .toastBody {
        flex-grow: 1;
        font-size: 15px;
      }
    }
  }
}