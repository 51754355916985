
.paginate {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.previousButton {
  padding: 12px;
  border: 1px solid white;
  //background-color: whitesmoke;
  color: #71797E;
  border-radius: 6px;

}

.nextButton {
  padding: 12px;
  border: 1px solid white;
  //background-color: whitesmoke;
  color: #71797E;
  border-radius: 6px;
}
.middleButton.current{
  color: #fff !important;
  background: #29ba74!important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.middleButton {
  height: 35px;
  width: 35px;
  padding: 0;
  line-height: 30px;
  display: inline-block;
  margin: 0 5px;
  color: #A098AE !important;
  border-radius: 6px;
}

.inputField {
  width: 50px;
  padding: 10px
}

.pageInputContainer {
  font-size: 14px;
  font-weight: bold;
}

.dropDown {
  width: 60px;
  padding: 10px;
  background-color: white;
  color: #333333;
  border: 1px solid #888888;
  border-radius: 10px;
}

.paginate .active {
  background-color: #005959;
  color: white;
}