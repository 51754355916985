.user{
  .editIconProject {
    height: 30px;
    width: 30px;
    background-color: #1b4c93;
    color: #000;
    border-radius: 3rem;
    transition: .2s;
    margin-bottom: 0;

    :hover {
      cursor: pointer;
    }
  }
}