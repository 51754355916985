.drawingStrip {
  width: auto;
  max-width: 100%;
  min-height: 135px;
  background-color: #F5F5F5;
  display: flex;
  padding: 0.1rem 0.5rem 0.5rem 0.5rem;
  overflow-x: auto;
  margin-bottom: 1rem;

  .drawingCard {
    width: 90px;
    min-width: 90px;
    height: 100px;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-right: 0;
    }
    .icon {
      width: 58px;
      height: 58px;
    }

    .header {
      flex-grow: 1;
      background-color: #CCC;
      cursor: pointer;
    }

    .body {
      padding: 0;
      font-size: 12px;
      color: #555;
      display: flex;
      small {
        flex-grow: 1;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 0.1rem;
      }
      .btn {
        padding: 0.2rem;
        font-size: 12px;
        align-self: end;
      }
    }
  }

}