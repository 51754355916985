@import "../../../../../../../assets/scss/variables";
.documentList{
  .dropdown{
    height: 40px;
    width: 30px;
  }

  .dropdown-toggle:before {
    display: none !important;
  }
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;
  }
  .dropdown-toggle:active {
    background: $light;
    border: none;
  }
  .dropdown-menu {
    background-color: white;
    box-shadow: 0 0 5px #0005;
    padding: 0.7rem 0.2rem;
    border: none;
    max-width: 80px; //This, by the way, is not working for some reason.
    border-radius: 0.4rem;
    margin: 0;
  }
  .imgThumbnail{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display:flex;
    font-size: 12px;
    height: 35px;
    width:35px;
    border-radius:3rem;
    object-fit:cover;
  }

  .editIconMember{
    height: 30px;
    width: 30px;
    background-color: #EEEEEE;
    color: #000;
    border-radius: 3rem;
    transition: .2s;
    margin-bottom:0;
    margin-right: -0.4rem;
    :hover{
    cursor: pointer;
  }
  }
}


