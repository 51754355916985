.shrink {
  transform: scale(0.9);
}

.shimmer-overlay {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0.1) 25%,
                  rgba(255, 255, 255, 0.3) 50%,
                  rgba(255, 255, 255, 0.1) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  from {
    background-position: -200% 0;
  }
  to {
    background-position: 200% 0;
  }
}

