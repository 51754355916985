

.documentModal{
  height: auto !important;

  .modal-content  {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    height: auto !important;
  }
}