.projectDetailPage {
    padding: 1.5rem 3rem;
    .backArrow {
        width: 18px;
        height: 18px;
    }

    .nav-tabs {
        border: none!important;
        .nav-link {
            font-weight: 500;
            color: #717171!important;
            &:hover, &:active {
                border-color: white!important;
            }
            &.active {
                background-color: transparent!important;
                color: #333 !important;
                border: none;
                border-bottom: 2px solid #333;
                border-bottom-color: #444 !important;
            }
        }
    }
    .tabMenuItem {
        padding: 0;
    }
}