@import "../../../../../../../assets/scss/variables";
.clients {
  .active {
    background-color: $light!important;
    border: none!important;
    border-radius: 0.4rem!important;
  }
  .clientCard {
    width: 100%;
    background-color: white;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    padding: 0.4rem 1.2rem;
  }
  .imgThumbnail{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display:flex;
    font-size: 12px;
    height: 35px;
    width:35px;
    border-radius:3rem;
    object-fit:cover;
  }

  .dropdown{
    height: 40px;
    width: 30px;
  }

  .dropdown-toggle:after {
    display: none !important;
  }
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;
  }
  .dropdown-toggle:active {
    background: $light;
    border: none;
  }
  .dropdown-menu {
    background-color: white;
    box-shadow: 0 0 5px #0005;
    padding: 0.7rem 0.2rem;
    border: none;
    max-width: 80px; //This, by the way, is not working for some reason.
    border-radius: 0.4rem;
    margin: 0;
  }
  .imgThumbnail{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display:flex;
    font-size: 12px;
    height: 35px;
    width:35px;
    border-radius:3rem;
    object-fit:cover;
  }
}