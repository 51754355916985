@import "../../../assets/scss/variables";
.imageUploadWrapper
{
  background-color: rgb(241,241,241) ;
  max-height: 160px;
  border: none;
  .imageContainer
  {

    border:2px dotted grey;
  }
  .droptext
  {
    font-size:15px;
    color:rgb(174,174,174)
  }
  .or
  {
    letter-spacing: 1px;
    font-size:17px;
    color:rgb(174,174,174)
  }

  .previewImage {
    width: 100%;
    height: 100%;
    max-height: 160px;
    object-fit: contain;
  }
  .closeBtn {
    width: 26px;
    height: 26px;
    position: absolute;
    right: -10px;
    top: -10px;
    border: 1px solid $border-color;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .loadingOverlay {
    background-color: #FFFA;
  }
}