.appHeader {
  display: flex;
  justify-content: space-evenly;

  .logo {
    width: 7rem;
    height: 3rem;
    align-self: center;
  }

  .menuBar {
    padding: 0.8rem 3rem;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  small {
    font-size: 14px;
    font-weight: 500;
  }
}
