.clientDocumentCard {
  background-color: white;
  border-radius: 0.3rem;
  padding: 1rem;


  .nav-tabs {
    border: none!important;
    .nav-link {
      font-weight: 500;
      color: #71797E;
      &:hover, &:active {
        border-color: white!important;
      }
      &.active {
        background-color: white!important;
        color: #333 !important;
        border: none;
        border-bottom: 2px solid #333;
        border-bottom-color: #444 !important;
      }
    }
  }
}