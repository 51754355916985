@import "../../assets/scss/variables";
.modelChecker {
  width: 100%;
  height: 100%;
  display: flex;
  background: white;

  .sidebar {
    min-width: 280px;
    width: 280px;
    height: 100%;
    border-right: 1px solid $border-color;

    .header {
      background-color: $primary;
      .inputArea {
        color: white;
        padding: 1rem;
        .help {
          font-size: 12px;
        }
      }
    }
  }

  main {
    width: 100%;
    height: 100%;
    //padding: 1rem;
    overflow: auto;

    .contentLabel {
      font-size: 17px;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .contentTable {
      td, th {
        background: white;
      }
      .innerRow {
        box-shadow: inset 0 0 4px #0006;
        padding: 0.8rem;
        //border: 1px solid #333;
      }

      .innerTable {
        border-top: 0;
        outline: none;
        thead {

          border-bottom: none;
          border-top: none;
          tr {
            border-bottom: none;
            border-top: none;
            box-shadow: 0 0 5px 0 #0007;
          }

          th {
            border: 0;
            background: #D2D2D2;
            font-weight: normal;
          }
        }
        tbody {
          //border-top: 0;
          tr {
            border-top: 0;
          }

          td {
            background-color: $light;
          }
        }

      }

      .active {
        td {
          //background: $light;
        }
      }
    }
  }
}