//@import "../../../../../../assets/scss/theme";
$border-color: #AAA;
$border-radius: 1rem;
.settings {

  table {
    border-collapse: separate!important;
    border-spacing: 0 0.5em;
    tbody {
      tr {
        //background: #FFF;
        td {
          background: #FFF;

          border-top: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          padding: 1em;
          &:first-child {
            background: transparent;
            border: none;
          }
          &:nth-child(2) {
            border-top-left-radius:  $border-radius;
            border-bottom-left-radius:  $border-radius;
            border-left: 1px solid #ffffff;
          }
          &:last-child {
            border-top-right-radius:  $border-radius;
            border-bottom-right-radius:  $border-radius;
            border-right: 1px solid #ffffff;
          }
        }
      }
      .archieve{
        tr{
          background-color: #71797E;
        }
      }
    }
  }
  .uploadIcon{
    color:#ffffff;
  }

}