.members{


  .member{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display:flex;
    font-size: 12px;
    height: 35px;
    width:35px;
    border-radius:3rem;
    object-fit:cover;
    margin-right: -0.4rem;
  }


  .imgThumbnail{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display:flex;
    font-size: 12px;
    height: 35px;
    width:35px;
    border-radius:3rem;
    object-fit:cover;
  }
  .user-filter-chip {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDD;
    border-radius: 50%;
    font-size: 12.6px;
    transition: .2s;
    border: 2px solid white;
    margin-right: -0.4rem;
  }
}