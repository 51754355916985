@import "../../../../../../../assets/scss/variables";
.documentModal{

  .modal-content  {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
  }

  fieldset {
    border: 1px dashed $border-color;
  }
}

.integration_modal {
  height: auto !important;

  .modal-content {
    height: auto !important;
  }
}