@import "../../assets/scss/variables";
$border-radius: 0.9rem;

//.modal-dialog {
//    height: 100%;
//    .modal-content {
//        height: 80%;
//        width: 100%;
//        overflow: hidden;
//    }
//}
.chatroom {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;

    .sidebar {
        min-width: 180px;
        max-width: 180px;
        width: 180px;
        border-right: 2px solid $border-color;
        display: flex;
        flex-direction: column;

        .chatEntry {
            border-radius: 0.3rem;
        }

        .active {
          background-color: #3273BA1A;
        }
    }

    .header {
        padding: 1.1rem 1.2rem;
        //background-color: #F8F8F2;
        color: $secondary;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .title {
            img {
                width: 22px;
                height: 22px;
            }
            .heading {
                font-size: 1rem;
                margin-left: 0.2rem;
            }
        }


        img, .bi {
            width: 20px;
            height: 20px;
            font-size: 20px;
        }
        .bi {
            margin: 0;
            line-height: 100%;
        }
    }

    .footer {
        padding: 1.2rem;

        .messageBox {
            box-shadow: 0 0 3px #0002;
            border-radius: $border-radius;
            margin-right: 1rem;
            height: 45px;
            border: none;
            padding: 0.6rem 1rem;
            &::placeholder {
                font-size: 16px;
                align-self: center;
                //transform: translateY(8px) translateX(7px);
            }
            &:active, &:focus-visible {
                box-shadow: 0 0 6px #0005;
                border: none!important;
                outline: none!important;
            }
        }
        .btn-accent {
            align-self: center;
            padding: 0.5rem 0.8rem;
            background-color: $accent-color;
            border-radius: $border-radius;
        }
    }

    .content {
        flex-grow: 1;
        //padding: 1rem;
        padding: 1rem 1rem 0 1rem;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .chatInfoMessage {
            display: flex;
            justify-content: center;
            align-content: center;
            margin-top: 1.5rem;
            margin-bottom: .5rem;
            position: sticky!important;
            //bottom: 0;


            .chatContent {
                background-color: #F2F2F2;
                border: 1px solid $border-color;
                padding: .8rem 1rem ;
                border-radius: $border-radius;
                color: $warning;
            }
        }

        .chatBubbleLeft, .chatBubbleRight {
            margin-bottom: 0.5rem;
            .chatUserInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .chatContent {
                max-width: 85%;
                border-radius: $border-radius;
                align-self: self-start;
                padding: 0.7rem;
                font-weight: normal;

            }
        }

        .chatBubbleLeft {
            display: flex;
            .chatUserIndicator {
                background-color: #D9D9D9;
                border-radius: 50%;
                width: 40px;
                height: 40px;
            }
            .chatContent {
                margin-left: 1rem;
                background-color: #F5F5F5;
            }
        }
        .chatBubbleRight {
            display: flex;
            justify-content: end;

            .chatUserIndicator {
                background-color: #FFA9A9;
                border-radius: 50%;
                width: 40px;
                height: 40px;
            }
            .chatContent {
                margin-right: 1rem;
                background-color: #EAFDE7;
            }
        }
    }
}