@import "../../../../../../../assets/scss/variables";
.row-cols-2 {
  .col:nth-of-type(even) {
    padding-right: 0;
  }
}
.cardIntegration {
  background-color: white;
  border-radius: $border-radius-sm;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $bg;
    opacity: 0.5;
    z-index: 2;
  }

  .user-profile{
    background: #EEEEEE;
    border: 2px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    height: 30px;
    justify-content: center;
    align-items: center;

  }
  .form-control {
    background-color: transparent;
    border: 1px dashed $border-color;
  }

}

