.uploading-image {
    position: relative;
}

.image-container {
    position: relative;
    display: inline-block;
}

.spinnerAlignment {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - (30px/2));
    left: calc(50% - (30px/2));
}

/* Adjust the opacity of the image while uploading */
.image-container img {
    opacity: 0.6; /* Adjust the opacity level as needed (0.0 to 1.0) */
}
.uploadImage {
    display: flex;
    height: 150px;
    width: 200px;
    overflow: hidden;
}