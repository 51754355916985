@import "../../assets/scss/variables";
.initials {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $primary;
  padding: 0.2rem;
  font-size: 15px;
  color: white;
  border: 1px solid $border-color;
  margin-right: -0.4rem;


  img {
    width: 100%;
    height: 100%;
  }
}